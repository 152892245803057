import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const BusinessNatureDeco: React.FC = () => {
  return <div className="playground-business-nature-section__deco-content" aria-hidden="true">
    <StaticImage
      aria-hidden="true"
      className="playground-business-nature-section__deco-image"
      src={'../../assets/images/about-us/diamond-1.png'}
      loading="eager"
      placeholder="blurred"
      alt="diamond-1"
    />
    <StaticImage
      aria-hidden="true"
      className="playground-business-nature-section__deco-image"
      src={'../../assets/images/about-us/diamond-2.png'}
      loading="eager"
      placeholder="blurred"
      alt="diamond-2"
    />
    <StaticImage
      aria-hidden="true"
      className="playground-business-nature-section__deco-image"
      src={'../../assets/images/about-us/diamond-3.png'}
      loading="eager"
      placeholder="blurred"
      alt="diamond-3"
    />
    <StaticImage
      aria-hidden="true"
      className="playground-business-nature-section__deco-image"
      src={'../../assets/images/about-us/diamond-4.png'}
      loading="eager"
      placeholder="blurred"
      alt="diamond-4"
    />
  </div>
}

export { BusinessNatureDeco };
