import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { MetaverseReasons } from './MetaverseReasons';
import './metaverse-description.scss';
import { AnimatedHeader } from '../animated-header';

const MetaverseDescription: React.FC = () => {
  return (
		<section
			className="metaverse-description-section"
			aria-labelledby="metaverse-desc-section-title"
		>
      <div className="metaverse-description-section__deco-content" aria-hidden="true">
				<StaticImage
					aria-hidden="true"
					className="metaverse-description-section__deco-image"
					src={'../../assets/images/about-us/metaverse-boy.png'}
					loading="eager"
					placeholder="blurred"
					alt="meta-boy"
				/>
      </div>
			<div className="metaverse-description-section__main-content">
				<AnimatedHeader>
					<h2
						id="metaverse-desc-section-title"
						className="atmospheric-text"
					>
						Why Metaverse?
					</h2>
				</AnimatedHeader>
				<MetaverseReasons />
			</div>
		</section>
	)
};

export { MetaverseDescription };
