import React from 'react';

const MetaverseReasons: React.FC = () => {
	return (
		<>
		<ul className="corbel-text">
			<li>
				<p>Commercial Giants are jumping in.</p>
				<ul>
					<li>
						Microsoft, Facebook, Epic Games, Nvidia and many major
						brands have jumped onto the Metaverse bandwagon.
					</li>
					<li>
						Brands like Nike and Gucci are releasing NFT versions of
						their merchandises and running promotions in Metaverses. <sup>2</sup>
					</li>
				</ul>
			</li>
			<li>
				Nasdaq article claims “Real Estate developers are
				dumping millions” into Metaverses.
			</li>
			<li>
				The Metaverse concept was compelling enough for Facebook to
				rename themselves to “Meta” to shift their focus fully into the
				narrative.
			</li>
			<li>
				The Metaverse is not a risky new concept. Bloomberg covered
				Second Life, a metaverse holding over 600 million in GDP
				equivalence even before the rise of social media.
			</li>
			<li>
				Bloomberg article writes that the Metaverse is a Multi-Trillion
				dollar industry, quoting Epic’s CEO.<sup>3</sup>
			</li>
			<li>
				Earliest investors have seen as much as 50,000% returns on
				their investment in Metaverses.
			</li>
		<div className="corbel-text citation-wrapper" aria-hidden="true">
			<div>
				<sup>2</sup>https://www.nasdaq.com/articles/3-reasons-why-you-should-buy-property-in-the-metaverse
			</div>
			<div>
				<sup>3</sup>https://www.bloomberg.com/news/articles/2021-11-17/metaverse-is-a-multitrillion-dollar-opportunity-epic-ceo-says
			</div>
		</div>
		</ul>
		</>
	);
};

export { MetaverseReasons };
