import React from 'react';
import { ProductDetailItemProps } from './ProductDetailItem';
import { ProductDetailsList } from './ProductDetailsList';
import PentagonImage from '@images/about-us/pentagon.png';
import EarthMapImage from '@images/about-us/earthmap.png';
import SkyMapImage from '@images/about-us/skymap.png';
import './product-description.scss';
import { HexagonBGLayout } from '@/layouts/hexagon-bg-layout';

const details: ProductDetailItemProps[] = [
	{
		title: 'Structure',
		description:
			'The PLAYGROUND MMRPV is coalesced from multiple dimensional planes. Every plane is a realm of its own, each carrying its own distinctive theme. These Otherworld planes intertwine and intersect at a nucleus. We call this nucleus the EpiCenter.',
		image: PentagonImage,
	},
	{
		title: 'EpiCenter',
		description:
			'The EpiCenter is the core of PLAYGROUND. It is the futuristic central city where people live, work and play in. Towering skyscrapers and bewitching lights engulf the city, painting a picturesque view. <br/><br /> In here, one could do anything from catching up with friends to hosting business meetings, playing games, partying, shopping and even attending concerts or events. It parallels the world we exist in physically…but without its limits. <br /><br />In the EpiCenter, possibilities are infinite.',
		image: EarthMapImage,
	},
	{
		title: 'OtherWorlds',
		description:
			'The OtherWorlds are Game-centric planes. It is the very premise where our game, DarkLight, transpires.  Entering the OtherWorlds, one can expect to be geared and cloaked differently, ready to explore our MMORPG focused realms. At genesis, the OtherWorlds comprise of five differently themed worlds. These different dimensions are where adrenaline kicks in, preparing you for a myriad of engaging PvP battles, Boss Raids, Expeditions, Game Missions and many other exciting adventures.',
		image: SkyMapImage,
	},
];

const ProductDescription: React.FC = () => {
	return (
		<HexagonBGLayout className="product-description-section" Tag="section">
			<h2 id="product-desc-section-title" className="atmospheric-text">
				What are we building?
			</h2>
			<p className="corbel-text">
				By amalgamating elements from Web 3.0 Metaverses and eminent
				MMORPG concepts, PLAYGROUND works towards the birth of a new
				playing field called the MMRPV - Massively Multiplayer Role
				Playing 'Verse.{' '}
				<br />
				<br />
				Our platform is built on key concepts of fluidity and is thus
				multi-dimensional. The MMRPV is weaved with integration in mind,
				as we strive to be an aggregator to other Metaverse and GameFi
				Projects.
			</p>
			<ProductDetailsList list={details} />
		</HexagonBGLayout>
	);
};

export { ProductDescription };
