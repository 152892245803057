import React from 'react';

export interface ProductDetailItemProps {
  title: string;
  description: string;
  image: string;
}

const ProductDetailItem: React.FC<ProductDetailItemProps> = ({ title, description, image }: ProductDetailItemProps) => {
  return <li>
    <section>
      <div className="image-wrapper" aria-hidden="true">
        <img src={image} alt="" />
      </div>
      <div className="detail-content">
        <h3 className="atmospheric-text">{title}</h3>
        <p className="corbel-text" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  </li>
};

export { ProductDetailItem };
