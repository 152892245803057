import React from 'react';
import { ProductDetailItem, ProductDetailItemProps } from './ProductDetailItem';

export interface ProductDetailsListProps {
	list: ProductDetailItemProps[];
}

const ProductDetailsList: React.FC<ProductDetailsListProps> = ({
	list,
}: ProductDetailsListProps) => {
	return (
		<ul className="product-description-section__list">
			{list.map((item: ProductDetailItemProps, index: number) => (
				<ProductDetailItem {...item} key={index} />
			))}
		</ul>
	);
};

export { ProductDetailsList };
