import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import { StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AnimatedHeader } from '../animated-header';
import { LazyLoadBackgroundImage } from '../lazy-load-background-image';
import './playground-introduction.scss';

const query: StaticQueryDocument = graphql`
	query {
		bgImage: file(relativePath: { eq: "images/about-us/metaverse.jpg" }) {
			childImageSharp {
				gatsbyImageData(
					quality: 90
          placeholder: BLURRED
				)
			}
		}
		mobileBgImage: file(relativePath: { eq: "images/about-us/playground-intro-mobile.jpeg" }) {
			childImageSharp {
				gatsbyImageData(
					quality: 90
          placeholder: BLURRED
				)
			}
		}
	}
`;

const PlaygroundIntroduction: React.FC = () => {
	const { bgImage, mobileBgImage } = useStaticQuery(query);
	const { isSmallerScreen } = useGlobalSetting();

	return (
		<LazyLoadBackgroundImage
			Tag="section"
			imageGraphql={isSmallerScreen ? mobileBgImage : bgImage}
			className="playground-intro-section"
			additionalProps={{ ariaLabelledby: 'intro-section-title', style: {} }}
		>
			<AnimatedHeader>
				<h2 id="intro-section-title" className="atmospheric-text">
					What is PLAYGROUND?
				</h2>
			</AnimatedHeader>
			<p className="corbel-text">
				Parallel to the world we live, work and play in, the PLAYGROUND
				Metaverse is infused with core elements from MMORPGs and coupled
				with stunning graphics. <br />
				<br />
				We call this the MMRPV - <br />
				Massively Multiplayer Role Playing 'Verse
			</p>
		</LazyLoadBackgroundImage>
	);
};

export { PlaygroundIntroduction };
