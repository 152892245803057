import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import { HexagonBGLayout } from '@/layouts/hexagon-bg-layout';
import React from 'react';
import { AnimatedHeader } from '../animated-header';
import { BusinessNatureDeco } from './BusinessNatureDeco';
import './playground-business-nature.scss';

const PlaygroundBusinessNature: React.FC = () => {
	const { isSmallerScreen } = useGlobalSetting();
	return (
		<HexagonBGLayout
			className="playground-business-nature-section"
			Tag="section"
		>
			<div className="playground-business-nature-section__main-content">
				<AnimatedHeader>
					<h2
						id="business-nature-section-title"
						className="atmospheric-text"
						data-content="What we do:"
					>
						What we do:
					</h2>
				</AnimatedHeader>
				<h3 className="atmospheric-text">What is the Metaverse?</h3>
				<figure className="quote-block">
					<blockquote
						className="corbel-italic-bold-text"
						cite="https://www.morganstanley.com/ideas/metaverse-investing"
					>
						"The metaverse blurs the lines between our physical and
						digital lives, potentially transforming advertising,
						e-commerce, entertainment and education."
					</blockquote>
					<figcaption className="corbel-text">
						&mdash; Morgan Stanley Research<sup aria-hidden="true">1</sup>
					</figcaption>
				</figure>
				<p className="corbel-text description">
					The Metaverse is a parallel world where people can perform
					all critical aspects of life - including socialise, work,
					live and play - all in an immersive digital environment.
					<br /> <br />
					With many consumer applications already present in gaming,
					Morgan Stanley Research claims that the basic premise is not
					far-fetched.
				</p>
				<div aria-hidden="true" className="citation-wrapper corbel-text">
					<sup>1</sup> https://www.morganstanley.com/ideas/metaverse-investing
				</div>
			</div>
			<BusinessNatureDeco />
		</HexagonBGLayout>
	);
	return (
		<section
			className="playground-business-nature-section hexagon-bg"
			aria-labelledby="business-nature-section-title"
		>
			<div className="playground-business-nature-section__main-content">
				<AnimatedHeader>
					<h2
						id="business-nature-section-title"
						className="atmospheric-text"
						data-content="What we do:"
					>
						What we do:
					</h2>
				</AnimatedHeader>
				<h3 className="atmospheric-text">What is the Metaverse?</h3>
				<figure className="quote-block">
					<blockquote
						className="corbel-italic-bold-text"
						cite="https://www.morganstanley.com/ideas/metaverse-investing"
					>
						"The metaverse blurs the lines between our physical and
						digital lives, potentially transforming advertising,
						e-commerce, entertainment and education."
					</blockquote>
					<figcaption className="corbel-bold-text">
						&mdash; Morgan Stanley Research
					</figcaption>
				</figure>
				<p className="corbel-text description">
					The Metaverse is a parallel world where people can perform
					all critical aspects of life - including socialise, work,
					live and play - all in an immersive digital environment.
					<br /> <br />
					With many consumer applications already present in gaming,
					Morgan Stanley Research claims that the basic premise is not
					far-fetched.
				</p>
			</div>
			<BusinessNatureDeco />
		</section>
	);
};

export { PlaygroundBusinessNature };
