import React from 'react';
import PlayceArcadeImage from '@images/about-us/playce-arcade.png';
import './playce-introduction.scss';

const PlayceIntroduction: React.FC = () => {
  return (
		<section
			className="playce-introduction-section"
			aria-labelledby="playce-intro-section-title"
		>
      <div className="playce-introduction-section__deco-content" aria-hidden="true">
				<img src={PlayceArcadeImage} alt="playce-arcade" />
      </div>
			<div className="playce-introduction-section__main-content">
				<h2
					id="playce-intro-section-title"
					className="atmospheric-text"
				>
					What is PLAYCE?
				</h2>
				<p className="corbel-text">
        Unveil a world of intriguing nostalgia as you enter PLAYCE - a futuristic entertainment hub, inspired by acclaimed video game arcades of the 90’s. PLAYCE is an integrated space where various applications and games from independent developers can be hosted within PLAYGROUND.
        </p>
			</div>
		</section>
	)
};

export { PlayceIntroduction };
