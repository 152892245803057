import { MetaverseDescription } from "@/components/metaverse-description";
import { PlayceIntroduction } from "@/components/playce-introduction";
import { PlaygroundBusinessNature } from "@/components/playground-business-nature";
import { PlaygroundIntroduction } from "@/components/playground-introduction";
import { PlaygroundTeam } from "@/components/playground-team";
import { ProductDescription } from "@/components/product-description";
import { configs } from "@/constants/configs";
import { DefaultLayout } from "@/layouts/default-layout/DefaultLayout";
import "../styles/global.scss";

const pageName: string = "About Us";

export default function AboutUs() {
  return (
    <DefaultLayout pageTitle={`${configs.defaultPageTitle} - ${pageName}`}>
      <PlaygroundIntroduction />
      <PlaygroundBusinessNature />
      <MetaverseDescription />
      <ProductDescription />
      <PlayceIntroduction />
      {/* <PlaygroundTeam /> */}
    </DefaultLayout>
  );
}
